import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './styles.css';

export default ({
  title, content, bgColor, index, size,
}) => {
  const hasSeparator = index < size;
  const topCurve = index === 0;
  const downCurve = index === size;
  return (
    <Grid
      item
      xs={12}
      className={
        `service-concept-item
        ${hasSeparator ? 'separator-space' : ''}
        `
      }
    >
      <div
        className={
          `service-concept-item-content
          ${bgColor}
          ${topCurve ? 'top-curve' : ''}
          ${downCurve ? 'down-curve' : ''}
          `
        }
      >
        <Typography variant="h6">
          {' '}
          {title}
          {' '}
        </Typography>
        <Typography variant="body1">
          {' '}
          {content}
          {' '}
        </Typography>
      </div>
    </Grid>
  );
};
