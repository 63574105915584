import React from 'react';
import { Grid } from '@material-ui/core';
import DynamicServiceConceptItem from './DynamicServiceConceptItem';
import './styles.css';

export default ({ concepts }) => {
  const size = concepts.length - 1;
  return (
    <Grid container className="service-concept-container">
      {
        concepts.map(({ title, content, bgColor }, i) => (
          <DynamicServiceConceptItem
            title={title}
            content={content}
            bgColor={bgColor}
            index={i}
            size={size}
            key={i}
          />
        ))
      }
    </Grid>
  );
};
