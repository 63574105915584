const descriptions = [
  'Choosing the right strategies and processes to support your software applications will ensure its operations are rationalized and working as expected.',
  'Practically everything runs on software applications, so it’s ever more important to effectively maintain and support them. It is critical to keep your applications up and running, adapting to a changing environment and troubleshooting for any issues identified.',
  'Whether it’s a matter of errors identified, continuous improvement or standard maintenance, our skilled software developers will deal with it and live up to the challenge. The quality of our services is backed up by years of experience providing ongoing maintenance and support.',
];

const concepts = [
  {
    title: 'Application Enhancements',
    content: 'Upgrade the capacity of your app to fulfill broader requirements through extensions, technical, functional and ongoing enhancements.',
    bgColor: 'bg-concept-normal-gray',
  },
  {
    title: 'Configuration Management',
    content: 'Stability in the product’s performance, attributes, requirements and operational information, including version control and updates.',
    bgColor: 'bg-concept-light-gray',
  },
  {
    title: 'Troubleshooting & Bug Fixing',
    content: 'Cost-effective and in-time bug identification, fixing bugs with the appropriate techniques and tools.',
    bgColor: 'bg-concept-light-green',
  },
  {
    title: 'Performance Management',
    content: 'Monitoring and measuring relevant metrics to ensure the expected level of service, performance and availability of the application.',
    bgColor: 'bg-concept-dark-gray',
  },
];

export {
  descriptions,
  concepts,
};
