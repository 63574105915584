import React from 'react';
import { Typography } from '@material-ui/core';
import './styles.css';

export default ({ descriptions }) => (
  <div className="service-text-container">
    {
        descriptions.map((text, i) => (
          <Typography variant="body1" key={i} className="service-text-item">
            {' '}
            {text}
            {' '}
          </Typography>
        ))
      }
  </div>
);
